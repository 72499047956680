import { Flex, Stack } from "@chakra-ui/react";
// import Signup from "../components/Signup";
import SignupNoOtp from "../components/SignupNoOtp"
export default function SignupPage() {
  return (
    <Stack h="100vh" direction={{ base: "column", md: "row" }}>
      <Flex flex={1} alignItems="center" justify="center">
        <SignupNoOtp />
      </Flex>
    </Stack>
  );
}
