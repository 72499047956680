import React, { useContext, useEffect } from "react";
import { Spinner, Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Utils/auth";
import { checkPaymentToken } from "../Utils/api";

const PaymentAuth = () => {
  const navigate = useNavigate();
  const { encodedUrl, campaign } = useParams();
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    // Function to check if token exists in localStorage
    const checkPaymentTokenAndRedirect = async () => {
      const token = localStorage.getItem("token");
      console.log(encodedUrl, campaign);
      if (token) {
        navigate("/", { state: { parameter: "start" } });
      } else {
        try {
          const { data } = await checkPaymentToken(campaign, encodedUrl);
          const { type, token, email, name } = data;
          if (type === "login") {
            authCtx.login(token, email, name);
            navigate("/", { state: { parameter: "start" } });
          } else if (type === "signup") {
            authCtx.login(token, email, name);
            navigate("/", { state: { parameter: "start" } });
          } else {
            navigate("/error");
          }
        } catch (error) {
          console.error("Error:", error);
          navigate("/error");
        }
      }
    };

    checkPaymentTokenAndRedirect();
  }, [navigate, authCtx]);

  return (
    <Flex flexDir="column" h="100vh">
      <Spinner m="auto" size="xl" />
    </Flex>
  );
};

export default PaymentAuth;
