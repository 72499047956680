import {
  Button,
  Text,
  Center,
  Box,
  Flex,
  useToast,
  Progress,
  Spinner,
  Fade,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//components import
import NavbarInside from "../components/NavBarInside";
import Footer from "../components/Footer";
import SectionBreak from "../components/SectionBreak";
import RenderQuestion from "../Utils/RenderQuestion";
import TimelineQuestionnaire from "../components/TimelineQuestionnaire";
import DragFinalQues from "../components/DragFinalQues";
import FreeQues from "../components/FreeQues";

//auth and api imports
import AuthContext from "../Utils/auth";
import {
  getSectionBreakRes,
  saveCurrentQueston,
  getModelResponse,
  reportPageStaticDetails,
} from "../Utils/api";

//libraries import
import { animateScroll } from "react-scroll";

export default function Diagnostic() {
  const toast = useToast();
  const navigate = useNavigate();

  // States
  const [quesId, setQuesId] = useState();
  const [progress, setProgress] = useState();
  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState();
  const [sectionData, setSectionData] = useState();
  const [timelineData, setTimelineData] = useState();
  const [diagnosticData, setDiagnosticData] = useState();
  const [freeFormData, setFreeFormData] = useState();
  const [sectionNumber, setSectionNumber] = useState();
  const [fadeIn, setFadeIn] = useState(true);
  const [boxes, setBoxes] = useState([]);
  const [numbersString, setNumbersString] = useState();
  const authCtx = useContext(AuthContext);
  const [modelResLoading, setModelResLoading] = useState(false);

  const [show, setShow] = useState(true);

  const getInit = async () => {
    try {
      setLoading(true);
      const res = await getSectionBreakRes(authCtx.email);
      handleResponse(res);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleResponse = async (res) => {
    const {
      content,
      progress,
      section_data,
      timeline_statements,
      question,
      timeline_number,
    } = res.data;
    switch (content) {
      case "section":
        setSectionData(res.data);
        setCurrentState(content);
        setSectionNumber(section_data[0].number);
        setQuesId(0);
        setProgress(progress);
        break;
      case "timeline_question":
        setTimelineData(question);
        setCurrentState(content);
        setQuesId(timeline_number);
        setProgress(progress);
        setSectionNumber(res.data.section_number);
        break;
      case "diagnostic_question":
        setDiagnosticData(question);
        setCurrentState(content);
        setQuesId(question.id);
        setSectionNumber(question.question_section);
        setProgress(progress);
        break;
      case "freeform_question":
        setFreeFormData(question);
        setCurrentState(content);
        setQuesId(1);
        setProgress(progress);
        break;
      case "timeline_ordering":
        setBoxes(timeline_statements);
        setNumbersString(
          res.data.timeline_statements.map((item) => item.number).join(",")
        );
        setCurrentState(content);
        setQuesId(question.id);
        setProgress(progress);

        break;
      case "completed":
        setModelResLoading(true);
        let staticData;
        try {
          await getModelResponse(authCtx.email);
        } catch (error) {
        } finally {
          staticData = await reportPageStaticDetails();
          navigate("/", {
            state: {
              parameter: "report",
              staticData: staticData.campaign,
              origin_owner_email: staticData.origin_owner_email,
            },
          });
          setModelResLoading(false);
        }
        break;
    }
  };

  const handleError = (error) => {
    toast({
      title: "Error occurred",
      description: error.message,
      position: "top-right",
      status: "error",
      isClosable: true,
      duration: null,
    });
  };

  useEffect(() => {
    getInit();
  }, []);

  const getQuestion = async (response, section = null) => {
    try {
      setLoading(true);
      const requestData = {
        diagnostic_response_id: authCtx.email,
        current_question_id: quesId,
        response: response,
        section: parseInt(sectionNumber),
      };
      if (section === "timeline_ordering") {
        requestData.section = section;
      }
      const res = await saveCurrentQueston(requestData);
      handleResponse(res);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    setFadeIn(true);
    setShow(true);
    animateScroll.scrollToTop({
      duration: 500,
      smooth: "easeInOutQuint",
    });
  }, [quesId]);

  const renderQuestionComponent = () => {
    switch (currentState) {
      case "section":
        return (
          <Fade in={show}>
            <SectionBreak data={sectionData} />
            <Button
              mt={{ base: "5vh", md: "5vh" }}
              ml={{ base: "10vw", md: "4.7vw" }}
              mb={"16vh"}
              width={{ base: "80%", md: "20%" }}
              borderRadius={"63px"}
              height={"7vh"}
              bg="black"
              color="white"
              onClick={() => {
                setShow(false);
                getQuestion(1, parseInt(sectionNumber));
              }}
            >
              <Text fontSize={"16px"} fontWeight={"600"}>
                Proceed to section
              </Text>
            </Button>
          </Fade>
        );
      case "diagnostic_question":
        return (
          <Fade in={show}>
            <Flex
              id="primaryContainer"
              minHeight="90vh"
              width={["90vw", "90vw", "90vw", "1200px", "1200px"]}
              m="10px auto"
              flexDir="column"
              alignItems="center"
              justifyContent="space-between"
            >
              {diagnosticData && (
                <Flex
                  mt={{ base: "1vh", md: "70px" }}
                  width={{ base: "100vw", md: "800px" }}
                  className={`fade-container ${
                    fadeIn ? "fade-in" : "fade-out"
                  }`}
                >
                  <RenderQuestion
                    diagnosticData={diagnosticData}
                    authCtx={authCtx}
                    setShow={setShow}
                    fadeIn={fadeIn}
                    setFadeIn={setFadeIn}
                    submitAnswerNumber={getQuestion}
                    submitAnswerSingle={getQuestion}
                    submitAnswerMulti={getQuestion}
                    submitAnswerFreeFrom={getQuestion}
                  />
                </Flex>
              )}
            </Flex>
          </Fade>
        );
      case "timeline_question":
        return (
          <>
            <TimelineQuestionnaire
              setShow={setShow}
              submitTimelineAnswer={getQuestion}
              questionData={timelineData}
            />
          </>
        );

      case "timeline_ordering":
        return (
          <>
            <DragFinalQues
              submitTimelineOrder={getQuestion}
              boxes={boxes}
              setBoxes={setBoxes}
              numbersString={numbersString}
            />
            ;
          </>
        );

      case "freeform_question":
        return (
          <>
            <FreeQues
              questionData={freeFormData}
              submitAnswerFree={getQuestion}
              setShow={setShow}
            />
            ;
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Box minH="100vh">
        <NavbarInside />
        {modelResLoading && (
          <Box
            position="fixed"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="rgba(0, 0, 0, 0.5)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex="9999"
          >
            <Spinner size="lg" />
          </Box>
        )}
        <Flex flexDir={"column"}>
          {currentState !== "section" && (
            <Center>
              <Flex>
                <Box width="100%" justifyContent="center">
                  <Progress
                    value={progress}
                    borderRadius={"47px"}
                    size="lg"
                    bg="#EBEBEB"
                    width={["85vw", "85vw", "85vw", "550px", "550px"]}
                  />
                </Box>
              </Flex>
            </Center>
          )}

          {loading ? (
            <Center mt={loading ? 10 : 0}>{loading && <Spinner />}</Center>
          ) : (
            <>{currentState && renderQuestionComponent()}</>
          )}
        </Flex>
      </Box>
      <Box mt={{ base: "23vh", md: "5vh" }} mb={{ base: "-3vh", md: "0vh" }}>
        <Footer />
      </Box>
    </Box>
  );
}
