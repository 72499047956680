import {
  Button,
  Checkbox,
  Text,
  FormControl,
  FormLabel,
  Center,
  Input,
  Box,
  Stack,
  useToast,
  Image,
  Flex,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { forgotPassword } from "../Utils/api";
import AuthContext from "../Utils/auth";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);

  const handleChange = (event, setStateFunction) => {
    setStateFunction(event.target.value);
  };

  const handleForgotPassword = async () => {
    setLoading(true);
    try {
      const forgotRes = await forgotPassword({
        email: email,
      });

      handleForgotResponse(forgotRes);
    } catch (error) {
      handleForgotError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotResponse = async (forgotRes) => {
    if (forgotRes.status === "success") {
      toast({
        title: "Request Successful.",
        description: "Please check your email for the New password",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      authCtx.logout();
      setTimeout(() => {
        window.location = "/login";
      }, 2000);
    } else {
      toast({
        title: "Request Failed.",
        description: "Please check your information and try again.",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleForgotError = (error) => {
    toast({
      title: "Password Reset Failed.",
      description: "An unexpected error occurred during password reset.",
      status: "error",
      position: "top-right",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Stack h="100vh" direction={{ base: "column", md: "row" }}>
      <Flex flex={1} alignItems="center" justify="center">
        <Stack
          p={5}
          spacing={4}
          w={"95vw"}
          maxW={"xl"}
          style={{ borderWidth: "1px", borderRadius: "10px" }}
          border="1px solid black"
        >
          <Center>
            <Image
              src="/break_logo.avif"
              width="auto"
              height="6vh"
              objectFit="contain"
            />
          </Center>
          <Box>
            <Text fontSize={"24px"} fontWeight={600} letterSpacing={"-0.76px"}>
              Forgot Password
            </Text>
          </Box>
          <Stack spacing={4}>
            <FormControl isRequired id="email">
              <FormLabel fontSize={"16px"}>Email Address</FormLabel>
              <Input
                size="md"
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(event) => handleChange(event, setEmail)}
              />
            </FormControl>
          </Stack>

          <Stack spacing={4}>
            <Button
              size="md"
              isLoading={loading}
              onClick={handleForgotPassword}
              bg="black"
              color="white"
              rightIcon={<BsArrowRightShort />}
              mt="20px"
            >
              Get New Password in your Email
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </Stack>
  );
};

export default ResetPassword;
