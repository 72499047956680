import React, {useState} from "react";
import { Flex, Text, Textarea, Button } from "@chakra-ui/react";

const FreeFormQues = (props) => {
  const [answerArea, setAnswerArea] = useState();
  const handleInputChange = (event) => {
    const newData = event.target.value;
    setAnswerArea(newData);
  };
  return (
    <>
      <Flex
        flexDir="column"
        padding="40px"
        m="auto"
        key={props?.questionData.question_text}
        width={"800px"}
      >
        <Text
          fontSize={{ base: "24px", md: "28px" }}
          fontWeight={"900"}
          mt="10px"
        >
          {props?.questionData.question_text}
        </Text>
        <Text mt="5px" fontSize="16px" color="gray.700" fontStyle="italic">
          Please limit your response to 100 words.
        </Text>

        <Textarea m="10px 0" onChange={handleInputChange} />

        <Button
          isDisabled={answerArea ? false:true}
          mb={"5vh"}
          bg="black"
          color="white"
          borderRadius={"9px"}
          width="150px"
          onClick={() => {
            props.setShow(false);
            props.submitAnswerFreeFrom(answerArea);
          }}
        >
          Next Question
        </Button>
      </Flex>
    </>
  );
};

export default FreeFormQues;
