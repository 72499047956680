import React, { useState } from "react";
import {
  Flex,
  Text,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberDecrementStepper,
  Button,
  NumberInputStepper,
} from "@chakra-ui/react";

const NumberQues = (props) => {
  const [answerTextLocal, setAnswerTextLocal] = useState("");

  const handleChange = (value) => {
    setAnswerTextLocal(value);
  };
  const preventMinus = (event) => {
    if (event.key === "-") {
      event.preventDefault();
    }
  };

  const isButtonDisabled = answerTextLocal === "";

  return (
    <>
      <Flex
        flexDir="column"
        padding="40px"
        m="auto"
        key={props?.questionData.question_text}
        className={`fade-container ${props.fadeIn ? "fade-in" : "fade-out"}`}
        width={{ base: "400px", md: "800px" }}
      >
        <Text
          fontSize={{ base: "24px", md: "28px" }}
          fontWeight={"900"}
          mt="10px"
        >
          {props?.questionData.question_text}
        </Text>
        <Text mt="5px" fontSize="16px" color="gray.700" fontStyle="italic">
          Please enter a number.
        </Text>

        <NumberInput
          m="20px 0"
          onChange={handleChange}
          onKeyDown={preventMinus}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        <Button
          isDisabled={isButtonDisabled}
          bg="black"
          color="white"
          borderRadius={"9px"}
          width="150px"
          mb={"5vh"}
          onClick={() => {
            props.setShow(false);
            props.submitAnswerNumber(parseFloat(answerTextLocal));
          }}
        >
          Next Question
        </Button>
      </Flex>
    </>
  );
};

export default NumberQues;
