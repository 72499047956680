import {
  Button,
  Checkbox,
  Text,
  FormControl,
  FormLabel,
  Center,
  Input,
  Box,
  Stack,
  InputGroup,
  InputRightElement,
  useToast,
  Image,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { BsArrowRightShort } from "react-icons/bs";
import { resetPassword } from "../Utils/api";
import AuthContext from "../Utils/auth";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState([false, false]);
  const authCtx = useContext(AuthContext);
  const handleClick = (index) => {
    setShow((prevShow) => {
      const newShow = [...prevShow];
      newShow[index] = !newShow[index];
      return newShow;
    });
  };

  const handleChange = (event, setStateFunction) => {
    setStateFunction(event.target.value);
  };

  const handleResetPassword = async () => {
    if (oldPassword === newPassword) {
      toast({
        title: "Password Reset Failed.",
        description: "New password cannot be the same as the old password.",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setLoading(true);
    try {
      const resetRes = await resetPassword({
        email: email,
        old_password: oldPassword,
        new_password: newPassword,
      });

      handleResetResponse(resetRes);
    } catch (error) {
      handleResetError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleResetResponse = async (resetRes) => {
    if (resetRes.status === "success") {
      toast({
        title: "Password Reset Successful.",
        description: "Your password has been reset successfully.",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      authCtx.logout();
      setTimeout(() => {
        window.location = "/login";
      }, 2000);
    } else {
      console.log("Password reset failed due to incorrect information");
      toast({
        title: "Password Reset Failed.",
        description: "Please check your information and try again.",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleResetError = (error) => {
    console.log("Error during password reset (API call):", error);
    toast({
      title: "Password Reset Failed.",
      description: "An unexpected error occurred during password reset.",
      status: "error",
      position: "top-right",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Stack
      p={5}
      spacing={4}
      w={"95vw"}
      maxW={"xl"}
      style={{ borderWidth: "1px", borderRadius: "10px" }}
      border="1px solid black"
    >
      <Center>
        <Image
          src="/break_logo.avif"
          width="auto"
          height="6vh"
          objectFit="contain"
        />
      </Center>
      <Box>
        <Text fontSize={"24px"} fontWeight={600} letterSpacing={"-0.76px"}>
          Reset Password
        </Text>
      </Box>
      <Stack spacing={4}>
        <FormControl isRequired id="email">
          <FormLabel fontSize={"16px"}>Email Address</FormLabel>
          <Input
            size="md"
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(event) => handleChange(event, setEmail)}
          />
        </FormControl>
        <FormControl isRequired id="oldPassword">
          <FormLabel fontSize={"16px"}>Old Password</FormLabel>
          <InputGroup>
            <Input
              size="md"
              type={show[0] ? "text" : "password"}
              placeholder="Enter your old password"
              value={oldPassword}
              onChange={(event) => handleChange(event, setOldPassword)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={() => handleClick(0)}>
                {show[0] ? <BiHide /> : <BiShow />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl isRequired id="newPassword">
          <FormLabel fontSize={"16px"}>New Password</FormLabel>
          <InputGroup>
            <Input
              size="md"
              type={show[1] ? "text" : "password"}
              placeholder="At least 8 characters long"
              value={newPassword}
              onChange={(event) => handleChange(event, setNewPassword)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={() => handleClick(1)}>
                {show[1] ? <BiHide /> : <BiShow />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
      </Stack>

      <Stack spacing={4}>
        <Checkbox m={"6px 0 6px 0"} letterSpacing={"-0.14px"} fontSize={"16px"}>
          I agree to the
          <Text as={"b"} color={"black"}>
            {" "}
            Terms{" "}
          </Text>
          and
          <Text as={"b"} color={"black"}>
            {" "}
            Privacy Policy
          </Text>
          .
        </Checkbox>
        <Button
          size="md"
          isLoading={loading}
          onClick={handleResetPassword}
          bg="black"
          color="white"
          rightIcon={<BsArrowRightShort />}
          mt="20px"
        >
          Reset Password
        </Button>
      </Stack>
    </Stack>
  );
};

export default ResetPassword;
