import { Flex } from "@chakra-ui/react";

const HelpDesk = () => {
  return (
    <Flex
      flexDir="column"
      m="auto"
      p="20px 0"
      width={["90vw", "90vw", "90vw", "700px", "700px"]}
      height="820px"
    >
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSelQ4K_9pHk6E-JyhinbWaoBcf2FDy3xXVER03kikT6M6xPNg/viewform?embedded=true"
        width="100%"
        height="100%"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
    </Flex>
  );
};

export default HelpDesk;
