import React, { useContext, useState, useEffect } from "react";
import { Heading, Text, Flex, Image, Button, Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

import AuthContext from "../Utils/auth";

const CourseMaterials = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <>
      <Flex
        minHeight="100vh"
        justifyContent="center"
        flexDir="column"
        bgImg="https://i.imgur.com/NElmTZV.png"
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
      >
        <Flex
          flexDir="column"
          m="auto"
          alignItems="center"
          width={["90vw", "90vw", "90vw", "1200px", "1200px"]}
        >
          <Image
            src="break_logo.png"
            alt="Logo"
            width={["150px", "150px", "200px", "200px", "200px"]}
            mb="20px"
            mt="60px"
          />
          <Heading
            fontFamily="Avenir"
            color="white"
            fontWeight="900"
            width="85%"
            textAlign="center"
            fontSize={["24px", "24px", "24px", "32px", "32px"]}
          >
            Brain Pattern Mapping — Course Materials
          </Heading>
          <Text
            color="white"
            textAlign="center"
            mt="20px"
            fontSize={["18px", "18px", "18px", "20px", "20px"]}
            width={["85%", "85%", "85%", "650px", "650px"]}
          >
            <strong>
              Get ready to understand why you see the world the way you do, what
              mechanisms created your blind spots and actionable steps you can
              take to rapidly rewire.
            </strong>{" "}
            <br></br>
            <br></br>
            The following interactive videos and accompanying workbook exercises
            are intentionally sequenced to{" "}
            <strong>prepare you for your Brain Pattern Mapping session.</strong>
            <br></br>
            <br></br>
            <strong>
              You can find all of the course materials that you need below.
            </strong>
          </Text>

          <Flex mt="40px" mb="80px">
            <Button
              bg="yellow"
              width="fit-content"
              fontWeight="900"
              fontSize={["12px", "12px", "12px", "16px", "16px"]}
              m="auto"
              as="a"
              href="https://www.dropbox.com/scl/fi/soz6w8s1tzjb2wb5avb0u/BPM-Workbook-Lecture-Slides.pdf?rlkey=m3iiqnfjb63e7f0i5wj4xppb1&st=rqi85uld&dl=0"
              target="_blank"
              _hover={{
                bg: "white",
                color: "black",
              }}
            >
              STEP 1: DOWNLOAD THE WORKBOOK
            </Button>
          </Flex>
        </Flex>
      </Flex>

      {/* ==== */}
      {/* VIDEO LECTURE SECTION */}
      {/* ==== */}
      <Flex
        flexDir="column"
        justifyContent="center"
        width={["90vw", "90vw", "90vw", "1200px", "1200px"]}
        maxWidth={["90vw", "90vw", "90vw", "1200px", "1200px"]}
        m="auto"
        p="40px 0"
      >
        {/* VIDEO TITLE */}
        <Heading
          fontFamily="Avenir"
          textAlign="left"
          fontWeight="900"
          fontSize={["22px", "22px", "22px", "28px", "28px"]}
          width="100%"
          mt="40px"
          lineHeight="1.5"
        >
          Step 2: Watch the video lecture on Timeline Processing
        </Heading>

        {/* ROW ITEM */}
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          flexDir={["column", "column", "column", "row", "row"]}
          m="40px 0px 80px 0px"
        >
          {/* TEXT ITEM */}
          <Flex flexDir="column" width={["100%", "100%", "100%", "50%", "50%"]}>
            <Heading
              fontFamily="Avenir"
              fontWeight="900"
              fontSize={["18px", "18px", "18px", "22px", "22px"]}
            >
              Objective
            </Heading>
            <Text fontSize={["16px", "16px", "16px", "18px", "18px"]} mt="10px">
              The lens through which you view your world is a{" "}
              <strong>byproduct of your childhood experiences.</strong> This
              lecture discusses the specific factors that shape your brain’s
              definition of safety and walks you through a series of prompts to
              help you{" "}
              <strong>
                tap into the information needed to complete your Brain Pattern
                Mapping.
              </strong>
            </Text>
            <Heading
              fontFamily="Avenir"
              fontWeight="900"
              fontSize={["18px", "18px", "18px", "22px", "22px"]}
              mt="40px"
            >
              Takeaway
            </Heading>
            <Text fontSize={["16px", "16px", "16px", "18px", "18px"]} mt="10px">
              Your brain has been patterned by the{" "}
              <strong>
                cause and effect relationship of your adversely perceived
                repetitive childhood experiences.
              </strong>{" "}
              This pattern impacts how you engage with the world around you.
            </Text>
            <Heading
              fontFamily="Avenir"
              fontWeight="900"
              fontSize={["18px", "18px", "18px", "22px", "22px"]}
              mt="40px"
            >
              Action Step
            </Heading>
            <Text fontSize={["16px", "16px", "16px", "18px", "18px"]} mt="10px">
              Start to consider <strong>what was happening around you</strong>{" "}
              and who the most influential <strong>cast of character</strong>{" "}
              were in your early years.{" "}
              <strong>Respond to the prompts on pages 22-26</strong> and have
              your notes ready for the next lecture.
            </Text>
          </Flex>

          {/* VIDEO ITEM */}
          <Flex
            justifyContent="center"
            alignItems="center"
            mt="40px"
            flexDir="column"
            width={["100%", "100%", "100%", "50%", "50%"]}
          >
            <Flex
              width="fit-content"
              alignSelf={["center", "center", "center", "flex-end", "flex-end"]}
              flexDir="column"
              alignItems="center"
            >
              {isMobile ? (
                <iframe
                  src="https://player.vimeo.com/video/863676616?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  style={{
                    width: "300px",
                    height: "210px",
                    background: "black",
                    alignSelf: "flex-end",
                  }}
                ></iframe>
              ) : (
                <iframe
                  src="https://player.vimeo.com/video/863676616?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  style={{
                    width: "550px",
                    height: "310px",
                    background: "black",
                    alignSelf: "flex-end",
                  }}
                ></iframe>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* ==== */}
      {/* END VIDEO LECTURE SECTION */}
      {/* ==== */}

      {/* ==== */}
      {/* VIDEO LECTURE SECTION */}
      {/* ==== */}
      <Flex width="100%" bg="black" height="fit-content">
        <Flex
          flexDir="column"
          justifyContent="center"
          width={["90vw", "90vw", "90vw", "1200px", "1200px"]}
          m="auto"
          p="40px 0"
        >
          {/* VIDEO TITLE */}
          <Heading
            fontFamily="Avenir"
            textAlign="left"
            fontWeight="900"
            fontSize={["22px", "22px", "22px", "28px", "28px"]}
            width="100%"
            mt="40px"
            color="white"
            lineHeight="1.5"
          >
            Step 3: Watch the video lecture on the Timeline Process Exercise
          </Heading>

          {/* ROW ITEM */}
          <Flex
            flexWrap="wrap"
            justifyContent="center"
            flexDir={[
              "column-reverse",
              "column-reverse",
              "column-reverse",
              "row",
              "row",
            ]}
            m="40px 0px 80px 0px"
            alignItems="center"
          >
            {/* VIDEO ITEM */}
            <Flex
              justifyContent="center"
              alignItems="center"
              mt="40px"
              flexDir="column"
              width={["100%", "100%", "100%", "50%", "50%"]}
            >
              <Flex
                width="fit-content"
                alignSelf={[
                  "center",
                  "center",
                  "center",
                  "flex-start",
                  "flex-start",
                ]}
                flexDir="column"
                alignItems="center"
              >
                {isMobile ? (
                  <iframe
                    src="https://player.vimeo.com/video/853157907?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    style={{
                      width: "300px",
                      height: "210px",
                      background: "black",
                      alignSelf: "flex-start",
                    }}
                  ></iframe>
                ) : (
                  <iframe
                    src="https://player.vimeo.com/video/853157907?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    style={{
                      width: "550px",
                      height: "310px",
                      background: "black",
                      alignSelf: "flex-start",
                    }}
                  ></iframe>
                )}
              </Flex>
            </Flex>

            {/* TEXT ITEM */}
            <Flex
              flexDir="column"
              width={["100%", "100%", "100%", "50%", "50%"]}
            >
              <Heading
                fontFamily="Avenir"
                fontWeight="900"
                fontSize={["18px", "18px", "18px", "22px", "22px"]}
                color="white"
              >
                Objective
              </Heading>
              <Text
                fontSize={["16px", "16px", "16px", "18px", "18px"]}
                mt="10px"
                color="white"
              >
                The Timeline Process Exercise is an{" "}
                <strong>
                  important tool that serves as a foundation for your Brain
                  Pattern Map.
                </strong>{" "}
                This lecture provides a step-by-step breakdown of how to
                <strong>identify and summarize</strong> your repetitive
                childhood experiences into{" "}
                <strong>
                  3 concise data streams, each linked to an emotion.
                </strong>
              </Text>
              <Heading
                fontFamily="Avenir"
                fontWeight="900"
                fontSize={["18px", "18px", "18px", "22px", "22px"]}
                mt="40px"
                color="white"
              >
                Takeaway
              </Heading>
              <Text
                fontSize={["16px", "16px", "16px", "18px", "18px"]}
                mt="10px"
                color="white"
              >
                How you perceived safety and stability as a child provides us
                with more insight than the objective truth here.
                <strong>
                  Anything that does not support innocence, curiosity or love,
                  when experienced with repetition, can be a source of pain and
                  suffering.
                </strong>{" "}
                How you responded across the timeline between ages 0-14 informs
                your origin, protective and escalating emotions.
              </Text>
              <Heading
                fontFamily="Avenir"
                fontWeight="900"
                fontSize={["18px", "18px", "18px", "22px", "22px"]}
                mt="40px"
                color="white"
              >
                Action Step
              </Heading>
              <Text
                fontSize={["16px", "16px", "16px", "18px", "18px"]}
                mt="10px"
                color="white"
              >
                <strong>Complete pages 33 and 35</strong> of your Brain Pattern
                Mapping Workbook. Do your best to answer the prompts in a
                summarized way, focusing on details such as your{" "}
                <strong>
                  behavior, voice tone, body language, decision making, and the
                  general tendencies{" "}
                </strong>
                in your daily life. Refer back to the working definitions of
                emotional states when describing your emotional response. Have
                these pages ready for your diagnostic session.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* ==== */}
      {/* END VIDEO LECTURE SECTION */}
      {/* ==== */}

      {/* ==== */}
      {/* VIDEO LECTURE SECTION */}
      {/* ==== */}
      <Flex
        flexDir="column"
        justifyContent="center"
        width={["90vw", "90vw", "90vw", "1200px", "1200px"]}
        maxWidth={["90vw", "90vw", "90vw", "1200px", "1200px"]}
        m="auto"
        p="40px 0"
      >
        {/* VIDEO TITLE */}
        <Heading
          fontFamily="Avenir"
          textAlign="left"
          fontWeight="900"
          fontSize={["22px", "22px", "22px", "28px", "28px"]}
          width="100%"
          mt="40px"
        >
          Step 4: Watch the video lecture on Source Belief Pattern Etiologies
        </Heading>

        {/* ROW ITEM */}
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          flexDir={["column", "column", "column", "row", "row"]}
          alignItems="center"
          m="40px 0px 0px 0px"
        >
          {/* TEXT ITEM */}
          <Flex flexDir="column" width={["100%", "100%", "100%", "50%", "50%"]}>
            <Heading
              fontFamily="Avenir"
              fontWeight="900"
              fontSize={["18px", "18px", "18px", "22px", "22px"]}
            >
              Objective
            </Heading>
            <Text fontSize={["16px", "16px", "16px", "18px", "18px"]} mt="10px">
              This lecture{" "}
              <strong>
                walks you through the initial stages of understanding how your
                childhood shaped your beliefs
              </strong>{" "}
              about people, situations and the world around you. You'll learn
              about how origin and adaptive source belief patterns are formed
              and how they shape the way you perceive reality, respond to your
              environment and make decisions about your future.
            </Text>
            <Heading
              fontFamily="Avenir"
              fontWeight="900"
              fontSize={["18px", "18px", "18px", "22px", "22px"]}
              mt="40px"
            >
              Takeaway
            </Heading>
            <Text fontSize={["16px", "16px", "16px", "18px", "18px"]} mt="10px">
              In preparation for your upcoming Brain Pattern Mapping session,
              you should have a basic understanding of the different source
              beliefs and what <strong>inputs</strong> contributed to the{" "}
              <strong>output</strong> of who you are. Understanding{" "}
              <strong>where your brain is fundamentally stuck</strong> in terms
              of socio-emotional maturity is an important step in the diagnostic
              process.
            </Text>
            <Heading
              fontFamily="Avenir"
              fontWeight="900"
              fontSize={["18px", "18px", "18px", "22px", "22px"]}
              mt="40px"
            >
              Action Step
            </Heading>
            <Text fontSize={["16px", "16px", "16px", "18px", "18px"]} mt="10px">
              Block off <strong>30 minutes of uninterrupted time</strong> on
              your calendar to complete your online diagnostics session using
              the computer model.
            </Text>
          </Flex>

          {/* VIDEO ITEM */}
          <Flex
            justifyContent="center"
            alignItems="center"
            mt="40px"
            flexDir="column"
            width={["100%", "100%", "100%", "50%", "50%"]}
          >
            <Flex
              width="fit-content"
              alignSelf={["center", "center", "center", "flex-end", "flex-end"]}
              flexDir="column"
              alignItems="center"
            >
              {isMobile ? (
                <iframe
                  src="https://player.vimeo.com/video/853169120?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  style={{
                    width: "300px",
                    height: "210px",
                    background: "black",
                    alignSelf: "flex-end",
                  }}
                ></iframe>
              ) : (
                <iframe
                  src="https://player.vimeo.com/video/853169120?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  style={{
                    width: "550px",
                    height: "310px",
                    background: "black",
                    alignSelf: "flex-end",
                  }}
                ></iframe>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* ==== */}
      {/* END VIDEO LECTURE SECTION */}
      {/* ==== */}

      {/* ==== */}
      {/* CTA SECTION */}
      {/* ==== */}

      <Footer public={false} />
    </>
  );
};

export default CourseMaterials;
