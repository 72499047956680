import {
  Button,
  Checkbox,
  Text,
  FormControl,
  FormLabel,
  Center,
  Input,
  Box,
  Stack,
  InputGroup,
  InputRightElement,
  useToast,
  Flex,
  Image,
} from "@chakra-ui/react";
import { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BiHide, BiShow } from "react-icons/bi";
import { BsArrowRightShort } from "react-icons/bs";
import AuthContext from "../Utils/auth";
import { login } from "../Utils/api";

const Login = () => {
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const authCtx = useContext(AuthContext);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleClick = () => setShow(!show);

  const handleChange = (event, setStateFunction) => {
    const { type, checked, value } = event.target;
    setStateFunction(type === "checkbox" ? checked : value);
  };

  const getLogin = async () => {
    setLoading(true);
    try {
      const loginRes = await login({
        username: email,
        password,
      });

      handleLoginResponse(loginRes);
    } catch (error) {
      handleLoginError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoginResponse = (loginRes) => {
    if (loginRes.status === "success") {
      authCtx.login(
        loginRes.data.token,
        loginRes.data.email,
        loginRes.data.name
      );
      window.location = "/";
    } else {
      console.log("login failed due to wrong credentials");
      toast({
        title: "Login Failed.",
        //desc non field to be changes to only string
        description: "Wrong Credentials",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleLoginError = (error) => {
    console.log("Error during login(Api call):", error);
    toast({
      title: "Login failed.",
      description: "An unexpected error occurred during login.",
      status: "error",
      position: "top-right",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Stack
      p={5}
      spacing={4}
      w={"95vw"}
      maxW={"xl"}
      style={{ borderWidth: "1px", borderRadius: "10px" }}
      border="1px solid black"
    >
      <Center>
        <Image
          src="/break_logo.avif"
          width="auto"
          height="6vh"
          objectFit="contain"
        />
      </Center>
      <Box>
        <Text fontSize={"24px"} fontWeight={600} letterSpacing={"-0.76px"}>
          Log In
        </Text>
        <Text fontSize={"14px"} color={"#717171"}>
          Let's get started with your Brain Pattern Mapping.
        </Text>
      </Box>
      <Stack spacing={4}>
        <FormControl isRequired id="email">
          <FormLabel fontSize={"16px"}>Email Address</FormLabel>
          <Input
            size="md"
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(event) => handleChange(event, setemail)}
          />
        </FormControl>
        <FormControl isRequired id="password">
          <FormLabel fontSize={"16px"}>Enter Password</FormLabel>
          <InputGroup>
            <Input
              size="md"
              type={show ? "text" : "password"}
              placeholder="Atleast 8 characters long"
              value={password}
              onChange={(event) => handleChange(event, setPassword)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
      </Stack>

      <Stack spacing={4}>
        <Checkbox
          onChange={(event) => handleChange(event, setIsChecked)}
          m={"6px 0 6px 0"}
          letterSpacing={"-0.14px"}
          fontSize={"16px"}
        >
          I agree to the
          <Text as={"b"} color={"black"}>
            {" "}
            Terms{" "}
          </Text>
          and
          <Text as={"b"} color={"black"}>
            {" "}
            Privacy Policy
          </Text>
          .
        </Checkbox>
        <Button
          size="md"
          isLoading={loading}
          onClick={getLogin}
          bg="black"
          color="white"
          rightIcon={<BsArrowRightShort />}
          mt="20px"
          _hover={{ bg: "gray" }}
          isDisabled={!email || !password || !isChecked}
          _disabled={{
            bg: "black",
            cursor: "not-allowed",
          }}
        >
          Login to your account
        </Button>
      </Stack>
      <Flex justifyContent="center">
        <a
          _hover={{ color: "gray.500" }}
          width="fit-content"
          cursor="pointer"
          fontSize="16px"
          href="/forgot-password"
        >
          {" "}
          Forgot Password ?
        </a>
      </Flex>
    </Stack>
  );
};

export default Login;
