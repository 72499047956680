import { Image, Flex, Text, Avatar, Wrap, WrapItem } from "@chakra-ui/react";
import { useContext } from "react";
import AuthContext from "../Utils/auth";

const NavbarInside = (props) => {
  const authCtx = useContext(AuthContext);

  function capitalizeFirstLetter(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map((word) => {
      return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
    });
    return capitalizedWords.join(" ");
  }

  return (
    <>
      <Flex
        flexDir="row"
        width="100%"
        height={"13vh"}
        justifyContent="space-between"
        alignItems="center"
        mb="20px"
        bg="#fff"
        color="#000"
      >
        <Flex>
          <Image
            ml={"5vw"}
            src="/break_logo.avif"
            width="auto" 
            height="6vh"
            objectFit="contain"
          />
        </Flex>
        <Flex className="avatar-resp" mr={"2vw"} width="fit-content">
          <Flex alignItems={"center"}>
            <Avatar name={authCtx.name} mr={"1vw"} />
          </Flex>
          <Flex mr={{ md: "5vw" }} justifyContent={"center"} flexDir={"column"}>
            <Flex>
              <Text as="b" color="rgba(0, 0, 0)">
                {authCtx.name ? capitalizeFirstLetter(authCtx.name) : null}
              </Text>
            </Flex>
            <Flex>
              <Text>{authCtx.email}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default NavbarInside;
