import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ChakraProvider, Flex, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import AuthContext from "./Utils/auth";
import "./index.css";
import LoginPage from "./Screens/LoginPage";
import SignupPage from "./Screens/SignupPage";
import Diagnostic from "./Screens/Diagnostic";
import Report from "./Screens/Report";
import PaymentAuth from "./Screens/PaymentAuth";
import DashboardRedirect from "./components/DashboardRedirect";
import Error from "./Screens/Error";
import HelpDesk from "./Screens/HelpDesk";
import HelpDeskNew from "./Screens/HelpDeskNew";
import ResetPasswordPage from "./Screens/ResetPassword";
import ForgotPasswordPage from "./Screens/ForgotPasswordPage";
import CourseMaterials from "./Screens/CourseMaterials";
import { getSectionBreakRes, reportPageStaticDetails } from "./Utils/api";
import LandingNew from "./Screens/LandingNew";

function App() {
  const authCtx = useContext(AuthContext);
  const loginStatus = authCtx.isLoggedIn;
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState();
  useEffect(() => {
    const checkReportStatus = async () => {
      try {
        setLoading(true);
        const sectionBreakRes = await getSectionBreakRes(authCtx.email);
        let staticData = null;
        let origin_owner_email = null;
        if (
          sectionBreakRes.data &&
          sectionBreakRes.data.content === "completed"
        ) {
          try {
            const res = await reportPageStaticDetails();
            staticData = res.campaign;
            origin_owner_email = res.origin_owner_email;
          } catch (error) {
            toast({
              title: "An error occurred",
              description: error.message,
              status: "error",
              isClosable: true,
              duration: null,
            });
          }
        }

        if (sectionBreakRes.data) {
          if (sectionBreakRes.data.content === "completed") {
            navigate("/", {
              state: {
                parameter: "report",
                staticData: staticData,
                origin_owner_email: origin_owner_email,
              },
            });
          } else if (sectionBreakRes.data.progress === 0) {
            navigate("/", { state: { parameter: "start" } });
          } else {
            navigate("/", { state: { parameter: "progress" } });
          }
        }
      } catch (error) {
        toast({
          title: error.message || "An error occurred",
          position: "top right",
          status: "error",
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    if (window.location.pathname === "/") {
      if (loginStatus) {
        checkReportStatus();
      }
    }
  }, []);

  // Private route component to redirect to login if not authenticated
  const PrivateRoute = ({ element }) => {
    return loginStatus ? element : <Navigate to="/login" />;
  };

  // Public route component to redirect to dashboard if already logged in
  const PublicRoute = ({ element }) => {
    return element;
  };
  if (loading) {
    return (
      <Flex minHeight="100vh" justifyContent="center" alignItems="center">
        <div className="loader"></div>
      </Flex>
    );
  }
  return (
    <>
      <ChakraProvider>
        <Routes>
          <Route
            index
            element={
              loginStatus ? (
                <PrivateRoute element={<LandingNew />} />
              ) : (
                <PublicRoute element={<LoginPage />} />
              )
            }
          />
          <Route path="/community/:customLink" element={<SignupPage />} />
          <Route
            path="/vxm3wrtktce9iyz"
            element={<PublicRoute element={<SignupPage />} />}
          />
          <Route
            path="/qva8nj9czdh8"
            element={<PublicRoute element={<SignupPage />} />}
          />
          <Route
            path="/login"
            element={<PublicRoute element={<LoginPage />} />}
          />
          <Route
            path="/reset-password"
            element={<PublicRoute element={<ResetPasswordPage />} />}
          />
          <Route
            path="/forgot-password"
            element={<PublicRoute element={<ForgotPasswordPage />} />}
          />
          <Route
            path="/course-materials"
            element={<PublicRoute element={<CourseMaterials />} />}
          />
          <Route
            path="/diagnostic"
            element={<PrivateRoute element={<Diagnostic />} />}
          />
          <Route
            path="/report"
            element={<PrivateRoute element={<Report />} />}
          />
          <Route
            path="/authorizer/:campaign/:encodedUrl"
            element={<PublicRoute element={<PaymentAuth />} />}
          />
          {/* <Route
            path="/start"
            element={<PrivateRoute element={<StartScreenNew />} />}
          /> */}
          {/* <Route
            path="/portal-redirect"
            element={<PrivateRoute element={<DashboardRedirect />} />}
          /> */}
          <Route
            path="/help"
            element={<PublicRoute element={<HelpDesk />} />}
          />
          <Route
            path="/help2"
            element={<PublicRoute element={<HelpDeskNew />} />}
          />
          <Route path="/error" element={<PublicRoute element={<Error />} />} />
          <Route path="/*" element={<Error />} />
        </Routes>
      </ChakraProvider>
    </>
  );
}

export default App;
